import { Backdrop, Button, createStyles, DialogContentText, PropTypes, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { Router, Route } from 'react-router-dom';
import { UILoader } from 'ui/feedback/UILoader';
import { UIPushNotificationSpec, UIPushNotification } from 'ui/notification/UIPushNotification';
import { AppRouter } from './presenters/navigation/AppRouter';
import { apiConfig } from './config/ApiConfiguration';
import './App.css';
import { UIDialog, UIDialogProps } from '../../ui/feedback/UIDialog';
import { strings } from './i18n/strings';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { createBrowserHistory } from 'history';
import { LoginCallback, Security } from '../../okta-react';

// API
export type PromptOptions = Partial<{
    title: string;
    confirmButtonColor: PropTypes.Color;
    confirmLabel: string;
    rejectLabel: string;
}>;

export interface AppContextSpec {
    displayLoader: (isLoading: boolean) => void;
    notify: (notification: UIPushNotificationSpec) => void;
    notifySuccess: (message: string) => void;
    notifyError: (message: string) => void;
    prompt: (message: string, callback: (isConfirmed: boolean) => void, opts?: PromptOptions) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
    })
);

export const useAsyncEffect = (asyncCallback: () => Promise<any>, deps?: Array<any>) => {
    const appContext = useContext(AppContext);

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        appContext.displayLoader(true);
        asyncCallback().then(() => appContext.displayLoader(false));
    }, deps);
};

export const AppContext = createContext<AppContextSpec>({
    displayLoader: (_) => {},
    notify: (_) => {},
    notifySuccess: (_) => {},
    notifyError: (_) => {},
    prompt: (_, __) => {},
});


const AppContainer = () => {
    const classes = useStyles();
    const [isLoading, setLoading] = useState(false);
    const [notification, setNotification] = useState<UIPushNotificationSpec | undefined>();
    const [prompt, setPrompt] = useState<Omit<UIDialogProps, 'children' | 'open'> & { message: string }>();
    const [promptOpened, setPromptOpened] = useState(false);

    const handlePrompt = (message: string, callback: (isConfirmed: boolean) => void, options?: PromptOptions) => {
        const opts: PromptOptions = {
            confirmLabel: strings.prompt.confirmLabel,
            rejectLabel: strings.prompt.rejectLabel,
            title: undefined,
            confirmButtonColor: 'primary',
            ...(options || {}),
        };

        const onClose = () => {
            setPromptOpened(false);
            setPrompt(undefined);
        };

        const onDecision = (isConfirmed: boolean) => {
            onClose();
            callback(isConfirmed);
        };

        const prompt = {
            onClose: setPromptOpened.bind(null, false),
            title: opts.title,
            actions: [
                <Button key="prompt-cancel" onClick={onDecision.bind(null, false)}>
                    {opts.rejectLabel}
                </Button>,
                <Button key="prompt-confirm" color={opts.confirmButtonColor} onClick={onDecision.bind(null, true)}>
                    {opts.confirmLabel}
                </Button>,
            ],
            message,
        };

        setPrompt(prompt);
        setPromptOpened(true);
    };

    return (
        <AppContext.Provider
            value={{
                displayLoader: setLoading,
                notify: setNotification,
                notifySuccess: (message) => setNotification({ message, severity: 'success' }),
                notifyError: (message) => setNotification({ message, severity: 'error' }),
                prompt: handlePrompt,
            }}
        >
            <div className="App">
                <AppRouter />
            </div>
            <Backdrop className={classes.backdrop} open={isLoading}>
                <UILoader />
            </Backdrop>
            <UIPushNotification value={notification} removeAfter={apiConfig.pushNotificationTTL} />
                {prompt && (
                    <UIDialog
                        open={promptOpened}
                        onClose={prompt.onClose}
                        title={prompt.title}
                        actions={prompt.actions}
                    >
                        <DialogContentText>{prompt.message}</DialogContentText>
                    </UIDialog>
                )}
        </AppContext.Provider>
    );
};

const oktaAuth = new OktaAuth({
    issuer : process.env.REACT_APP_OKTA_ISSUER,
    clientId : process.env.REACT_APP_OKTA_CLIENT_ID,
    redirectUri : window.location.origin + process.env.REACT_APP_OKTA_REDIRECT_URI_CALLBACK,
    scopes: ['openid', 'myGroupsAlgo'],
});

export const history = createBrowserHistory();
const App = () => {

    const restoreOriginalUri = async (_oktaAuth: any, originalUri: string) => {
        history.replace(toRelativeUrl(originalUri, window.location.origin));
    };
    return (
        <Router history={history}>
            <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
                <Route path={process.env.REACT_APP_OKTA_REDIRECT_URI_CALLBACK} exact component={LoginCallback} />
                <AppContainer />
            </Security>
        </Router>
    );
};

export default App;